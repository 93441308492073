import React from 'react';
import './App.css';

import { BubbleWrap, Column, ColumnItem } from './Library.jsx'
import Countdown from 'react-countdown-now';

const App = () => {
  return (
    <div className="App">
      <div id="content">
        <BubbleWrap y={25} x={40}>
          <Column>
            <ColumnItem width={1/5 * 100} style={{textAlign:"center"}} >
              <h1 style={{fontSize:"40px"}}>
                <span style={{color:"rgb(22, 137, 252)"}}>Kidz</span>
                <span style={{color:"rgb(252, 191, 41)"}}>Sentry</span>
              </h1>
              <img src="/KidzSentry.png"/>
            </ColumnItem>
            <ColumnItem width={4/5 * 100}>
              <BubbleWrap style={{paddingTop:"40px", paddingLeft:"50px"}}>
                <p style={{fontSize:"20px"}}>
                  <div style={{textAlign:"center", fontSize:"110%"}}>
                    <b>Ensuring the safety of children is a primary prerogative of a
                    civilized society.</b>
                  </div>
                  <br/>
                  Over 350 school shootings have occurred in the US
                  since the infamous Columbine shooting in 1999.
                  Achieving a complete solution is complex as
                  it involves gun control and mental health.
                  <br/><br/>
                  I always wondered why nobody would ask us kids what we would do
                  to make our lives safer. So I set out to do just that.<br/>
                  <br/>
                  <div style={{textAlign:"right"}}>- Nathaniel Dsouza&nbsp;&nbsp;&nbsp;</div>
                </p>
              </BubbleWrap>
            </ColumnItem>
          </Column>
        </BubbleWrap>
        {(!(new Date().valueOf() > 1560956400000)) && <center>
          <h1 style={{fontSize:"50px", margin:0, color:"#43a047"}}>Announcing in</h1>
          <h1 style={{fontSize:"150px", margin:0, color:'#43a047'}}><Countdown date={1560956400000}/></h1>
        </center>}
      </div>
    </div>
  );
};

export default App;
