import React from 'react';

let Column = (props) => <div className="columnContainer" {...props}>{props.children}</div>;
let ColumnItem = (props) => {
    let { style } = props;
    
    if(!style) {
        style = {};
    }
    style.width = (props.width) + "%";
    
    return <div className="column" style={style}>{props.children}</div>;
};

class BubbleWrap extends React.Component {
    render() {
        let styles = this.props.style || {};
        
        if(typeof(this.props.x) === "number") {
            styles.paddingLeft = this.props.x;
            styles.paddingRight = this.props.x;
        }

        if(typeof(this.props.y) === "number") {
            styles.paddingTop = this.props.y;
            styles.paddingBottom = this.props.y;
        }
        
        if(this.props.inline === true) {
            styles.display = "inline-block";
        }
        
        return (
            <div style={styles} className={"BubbleWrap"}>
                {this.props.children}
            </div>
        );
    }
}

export { BubbleWrap, Column, ColumnItem };